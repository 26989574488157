/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import GlobalStyle from 'theme/GlobalStyle';

import { objects } from 'helpers/objects';
import Header from '../components/header/header';
import LandingPage from './landingPage';
import ObjectPage from './objectPage';
import EventsPage from './eventsPage';
import TimetablePage from './timetablePage';
import WeatherPage from './weatherPage';
import TrailPage from './trailPage';

const App = () => {
  const { i18n } = useTranslation();
  const [languagePl, setLanguagePl] = useState(true);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguagePl(!languagePl);
  };
  const [embedded, setEmbedded] = useState(false);
  const [showSmallCard, setShowSmallCard] = useState(false);
  const [showFullCard, setShowFullCard] = useState(false);
  const [showMenuObjectSelect, setShowMenuObjectSelect] = useState(false);
  const handleShowMenuObjectSelect = () => {
    setShowMenuObjectSelect(!showMenuObjectSelect);
  };

  const [showMenuEventSelect, setShowMenuEventSelect] = useState(false);
  const handleShowMenuEventSelect = () => {
    setShowMenuEventSelect(!showMenuEventSelect);
  };

  const [showMenuStationSelect, setShowMenuStationSelect] = useState(false);
  const handleShowMenuStationSelect = () => {
    setShowMenuStationSelect(!showMenuStationSelect);
  };

  const [weatherData, setWeatherData] = useState([]);

  const closedCardOnClickNavigation = () => {
    setShowSmallCard(false);
    setShowFullCard(false);
  };

  useEffect(() => {
    async function fetchData() {
      const lat = 49.2956;
      const lon = 19.9512;
      axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },

        url: `https://api.bergregions.pl/api/v1/weather?lat=${lat}&lon=${lon}`,
      }).then((response) => {
        setWeatherData(response.data.currentCondition);
      });
    }
    fetchData();

    const check = () => {
      const minutes = new Date().getMinutes();
      if (minutes === 0 || minutes === 30) {
        fetchData();
      }
    };

    setInterval(() => {
      check();
    }, 1000 * 60);
  }, []);

  // ----- Add query param 'embedded' to turn on Touch version ----- //
  const handleChangeToTouch = () => {
    setEmbedded(true);
  };

  const [backToLandingPage, setBackToLandingPage] = useState(false);
  const timeRunning = 90 * 1000;
  let backTimeout;

  const back = () => {
    setBackToLandingPage(true);
    setShowSmallCard(false);
    setShowFullCard(false);
  };

  const setTimeouts = () => {
    backTimeout = setTimeout(back, timeRunning);
  };

  const clearTimeouts = () => {
    if (backTimeout) clearTimeout(backTimeout);
  };

  useEffect(() => {
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress', 'touchmove'];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
      setBackToLandingPage(false);
    };

    for (const i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (const i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, [setBackToLandingPage]);

  return (
    <>
      <GlobalStyle />
      <Header
        embedded={embedded}
        handleChangeToTouch={handleChangeToTouch}
        changeLanguage={changeLanguage}
        languagePl={languagePl}
        weatherData={weatherData}
        setShowMenuObjectSelect={setShowMenuObjectSelect}
        setShowMenuEventSelect={setShowMenuEventSelect}
        closedCardOnClickNavigation={closedCardOnClickNavigation}
      />
      {backToLandingPage && <Redirect to="/" />}
      <Switch>
        <Route exact path="/">
          <LandingPage
            objects={objects}
            languagePl={languagePl}
            handleShowMenuObjectSelect={handleShowMenuObjectSelect}
            showMenuObjectSelect={showMenuObjectSelect}
            setShowMenuObjectSelect={setShowMenuObjectSelect}
            showSmallCard={showSmallCard}
            setShowSmallCard={setShowSmallCard}
            showFullCard={showFullCard}
            setShowFullCard={setShowFullCard}
          />
        </Route>
        <Route path="/object">
          <ObjectPage
            objects={objects}
            languagePl={languagePl}
            handleShowMenuObjectSelect={handleShowMenuObjectSelect}
            showMenuObjectSelect={showMenuObjectSelect}
            setShowMenuObjectSelect={setShowMenuObjectSelect}
          />
        </Route>
        <Route path="/events">
          <EventsPage
            handleShowMenuEventSelect={handleShowMenuEventSelect}
            showMenuEventSelect={showMenuEventSelect}
            setShowMenuEventSelect={setShowMenuEventSelect}
          />
        </Route>
        <Route path="/timetable">
          <TimetablePage
            handleShowMenuStationSelect={handleShowMenuStationSelect}
            showMenuStationSelect={showMenuStationSelect}
            setShowMenuStationSelect={setShowMenuStationSelect}
          />
        </Route>
        <Route path="/weather">
          <WeatherPage />
        </Route>
        <Route path="/trail">
          <TrailPage languagePl={languagePl} />
        </Route>
      </Switch>
    </>
  );
};

export default App;
