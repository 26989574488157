import { useState, useEffect, useRef } from 'react';

const useImageLoaded = () => {
  const [loaded, setLoaded] = useState(false);
  const refImage = useRef();

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (refImage.current && refImage.current.complete) {
      onLoad();
    }
  });

  return [refImage, loaded, onLoad];
};

export default useImageLoaded;
