/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Route, Switch, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import DatePicker, { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import dayjs from 'dayjs';

import EventList from 'components/eventList/eventList';
import EventDetails from 'components/eventDetails/eventDetails';
import ButtonEventSelect from 'components/buttons/buttonEventSelect';
import LoaderDots from 'helpers/loader';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import styles from './index.module.scss';
import './react-datepicker.css';

registerLocale('pl', pl);

const Loading = styled.div`
  position: absolute;
  width: 100vw;
  top: 50%;
  margin-right: auto;
  margin-left: auto;
`;

const EventsPage = ({ handleShowMenuEventSelect, showMenuEventSelect, setShowMenuEventSelect }) => {
  const [loading, setLoading] = useState(false);
  const [eventsList, setEventList] = useState();
  // eslint-disable-next-line no-unused-vars
  const [showFilter, setShowFilter] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timeFilterOnOff, setTimeFilterOnOff] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      url: `https://api.bergregions.pl/api/v1/region-feature/24460239?featureType=EVENTS`,
    }).then((response) => {
      setEventList(response.data);
      setLoading(false);
    });
  }, [setEventList]);

  const startDateString = dayjs(startDate).format('YYYY-MM-DD');
  const endDateString = dayjs(endDate).format('YYYY-MM-DD');

  const SingleEvent = () => {
    const { id } = useParams();

    return (
      <div className={styles.eventsDetails}>
        <Link className={styles.events__buttonBack} to="/events">
          <Arrow className={styles.events__buttonBackIcon} />
        </Link>
        <EventDetails event={eventsList?.events.find((event) => event.id === Number(id))} />
      </div>
    );
  };

  return (
    <Switch>
      <Route exact path="/events">
        <div className={styles.wrapper}>
          <ButtonEventSelect
            showMenuEventSelect={showMenuEventSelect}
            handleShowMenuEventSelect={handleShowMenuEventSelect}
            showFilter={showFilter}
          />
          <div className={styles.events} onClick={() => setShowMenuEventSelect(false)}>
            <div className={styles.picker}>
              <div className={styles.picker__box}>
                <img
                  className={styles.picker__icon}
                  src={`${process.env.PUBLIC_URL}/assets/img/icon_calendar.svg`}
                  alt="Ikona kalendarza"
                />
                <DatePicker
                  locale="pl"
                  calendarStartDay={1}
                  dateFormat="yyyy-MM-dd"
                  className={styles.picker__input}
                  selected={startDate}
                  defaultValue={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setTimeFilterOnOff(true);
                  }}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
              <div className={styles.picker__box}>
                <img
                  className={styles.picker__icon}
                  src={`${process.env.PUBLIC_URL}/assets/img/icon_calendar.svg`}
                  alt="Ikona kalendarza"
                />
                <DatePicker
                  locale="pl"
                  calendarStartDay={1}
                  dateFormat="yyyy-MM-dd"
                  className={styles.picker__input}
                  selected={endDate}
                  defaultValue={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    setTimeFilterOnOff(true);
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
              </div>
            </div>
            <div className={styles.events__container}>
              {loading && (
                <Loading>
                  <LoaderDots />
                </Loading>
              )}
              {eventsList?.events
                .filter((attraction) =>
                  timeFilterOnOff
                    ? (attraction.startDate <= startDateString &&
                        attraction.endDate >= endDateString) ||
                      (attraction.startDate >= startDateString &&
                        attraction.endDate <= endDateString)
                    : attraction,
                )
                .flatMap((attraction) => (
                  <Link
                    to={`/events/${attraction.id}`}
                    className={styles.events__button}
                    key={attraction.id}
                    id={attraction.id}
                  >
                    <EventList attraction={attraction} />
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </Route>
      <Route path="/events/:id">
        <SingleEvent />
      </Route>
    </Switch>
  );
};

export default EventsPage;
