/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'moment/locale/en-gb';
import 'moment/locale/pl';

import React, { useEffect } from 'react';

import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import Navigation from './components/navigation';
import { getIcon } from '../../helpers/weather';
import styles from './header.module.scss';

const Header = ({
  changeLanguage,
  languagePl,
  weatherData,
  setShowMenuObjectSelect,
  setShowMenuEventSelect,
  embedded,
  handleChangeToTouch,
  closedCardOnClickNavigation,
}) => {
  const { t } = useTranslation();
  const date = new Date();

  // ----- Add query param 'embedded' to turn on Touch version ----- //
  useEffect(() => {
    if (window.location.href.includes('embedded')) {
      handleChangeToTouch();
    }
  }, []);

  return (
    <header
      className={styles.header}
      onClick={() => {
        setShowMenuObjectSelect(false);
        setShowMenuEventSelect(false);
      }}
    >
      <div className={styles.headerTop}>
        <div className={styles.headerTop__container}>
          {!embedded && (
            <div className={styles.date}>
              <p className={styles.date__time}>
                <Moment interval={1000} format="HH:mm" />
              </p>
              <p className={styles.date__date}>
                {languagePl ? (
                  <Moment interval={1000} format="DD MMMM YYYY" locale="pl" />
                ) : (
                  <Moment interval={1000} format="DD MMMM YYYY" locale="en-gb" />
                )}
              </p>
            </div>
          )}

          {/* ----- BUTTON HOME ON TOUCH ---- */}

          {embedded && (
            <button
              type="button"
              onClick={() => window.parent.postMessage('CLOSE_ZAKOPANE_APP', '*')}
              className={styles.home}
            >
              <img
                className={styles.home__icon}
                src={`${process.env.PUBLIC_URL}/assets/img/icon_home.svg`}
                alt="Zakopane"
              />
              <p className={styles.home__content}>{t('navigation.touchButton')}</p>
            </button>
          )}
          <div className={styles.logo}>
            <img
              className={styles.logo__logo}
              src={`${process.env.PUBLIC_URL}/assets/img/logo_zakopane_3.svg`}
              alt="Zakopane"
            />
            <p className={styles.logo__description}>{t('navigation.name')}</p>
          </div>
          <div className={styles.weather}>
            <div className={styles.weather__container}>
              <img
                className={styles.weather__icon}
                src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/${
                  date.getHours() < 21 && date.getHours() > 7 ? 'day' : 'night'
                }/${getIcon(weatherData.weatherCode)}`}
                alt="Ikona pogody"
              />
              <p className={styles.weather__temperature}>{weatherData.temperatureValue}&deg;C</p>
            </div>
            <button
              className={styles.weather__button}
              type="button"
              onClick={languagePl ? () => changeLanguage('en') : () => changeLanguage('pl')}
            >
              <img
                className={languagePl ? styles.weather__flag : styles.weather__flagDisabled}
                src={`${process.env.PUBLIC_URL}/assets/img/flag-poland.svg`}
                alt="Flaga wybranego języka"
              />
              <img
                className={!languagePl ? styles.weather__flagEn : styles.weather__flagDisabled}
                src={`${process.env.PUBLIC_URL}/assets/img/flag-en.svg`}
                alt="Flaga wybranego języka"
              />
              <p className={styles.weather__paragraph}>{languagePl ? 'PL' : 'EN'}</p>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.headerBottom}>
        <Navigation
          languagePl={languagePl}
          embedded={embedded}
          closedCardOnClickNavigation={closedCardOnClickNavigation}
        />
      </div>
    </header>
  );
};

export default Header;
